.skills-block { 
    min-height: 100vh;
    background-color: #092635;
    color: #9EC8B9;
}

.skills-card { 
    padding: 12px;
    /* text-align: left; */
    margin: 24px;
    background-color: #9EC8B9;
}

.skills-section { 
    padding-bottom: 12px;
    text-align: left;
}

.skills-section-title { 
    font-size: 25px;
    font-weight: 300;
    line-height: 1.5;
    padding: 12px;
}
