.resume-block { 
    min-height: 100vh;
    width: 100%;
    background-color: white;
    /* padding: 24px; */
}

.resume { 
    height: 100vh;
    width: 100%;
}

.no-scroll {
    overflow: hidden;
  }